import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function SixthAveHeartache() {
  return (
    <Layout>
      <Helmet title="6th Ave Heartache | The Wallflowers" />

      <h1>6th Ave Heartache</h1>

      <h2>The Wallflowers</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Sirens ring, the shots ring out</p>
          <p>A stranger cries, screams out loud</p>
          <p>I had my world strapped against my back</p>
          <p>I held my hands, never knew how to act</p>
        </Verse>
        <Chorus>
          <p>And the same black line</p>
          <p>That was drawn on you</p>
          <p>Was drawn on me</p>
          <p>And now it's drawn me in</p>
          <p>6th Avenue heartache</p>
        </Chorus>
        <Verse>
          <p>And below me was a homeless man</p>
          <p>I'm singin' songs, I knew complete</p>
          <p>On the steps alone, his guitar in hand</p>
          <p>It's 50 years, he stood where he stands</p>
        </Verse>
        <Chorus>
          <p>And the same black line</p>
          <p>That was drawn on you</p>
          <p>Was drawn on me</p>
          <p>And now it's drawn me in</p>
          <p>6th Avenue heartache (X2)</p>
        </Chorus>
        <Verse>
          <p>Now walkin' home on those streets</p>
          <p>The river winds move my feet</p>
          <p>Subway steam, like silhouettes in dreams</p>
          <p>They stood by me (they stood by me)</p>
          <p>(They stood by me, oh) just like moonbeams</p>
        </Verse>
        <Chorus>
          <p>And the same black line</p>
          <p>That was drawn on you</p>
          <p>Was drawn on me</p>
          <p>And now it's drawn me in</p>
          <p>6th Avenue heartache (X2)</p>
        </Chorus>
        <Verse>
          <p>Look out the window, down upon that street</p>
          <p>And gone like a midnight was that man</p>
          <p>But I see his six strings laid against that wall</p>
          <p>And all his things, they all look so small</p>
          <p>I got my fingers crossed on a shooting star</p>
          <p>Just like me, just moved on</p>
        </Verse>
        <Chorus>
          <p>And the same black line</p>
          <p>That was drawn on you</p>
          <p>Was drawn on me</p>
          <p>And now it's drawn me in</p>
          <p>6th Avenue heartache (X2)</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
